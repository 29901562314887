import { get, nthArg } from 'lodash';
import type { ThunkAction } from 'redux-thunk';
import type { HttpClient } from '@wix/http-client';
import type { IWixAPI, FlowAPI } from '@wix/yoshi-flow-editor';
import { queryPosts } from '@wix/ambassador-blog-v3-post/http';
import { Field } from '@wix/ambassador-blog-v3-post/types';
import {
  resolveId,
  createAction,
  urijs,
  type RequestClient,
} from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../experiments';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getDemoPosts } from '../../common/services/demo-posts';
import getEnvironment from '../../common/services/get-environment';
import { normalizePosts } from '../../common/services/post-utils';
import { getCategoryIds } from '../../common/store/categories/categories-selectors';
import { NormalizedPost } from '../../common/types';
import { AggregatorRequest } from '../../post-list-widget/types';

export const FETCH_RECENT_POSTS_REQUEST = 'recentPosts/FETCH_REQUEST';
export const FETCH_RECENT_POSTS_SUCCESS = 'recentPosts/FETCH_SUCCESS';
export const FETCH_RECENT_POSTS_FAILURE = 'recentPosts/FETCH_FAILURE';

export const fetchRecentPostsRequest = createAction(FETCH_RECENT_POSTS_REQUEST);
export const fetchRecentPostsSuccess = createAction(
  FETCH_RECENT_POSTS_SUCCESS,
  nthArg(0),
  nthArg(1),
);
const fetchRecentPostsFailure = createAction(
  FETCH_RECENT_POSTS_FAILURE,
  nthArg(0),
  nthArg(1),
);

type FetchRecentPostsThunkAction<T = any> = ThunkAction<
  Promise<T> | T,
  any,
  FetchRecentPostsThunkArgs,
  any
>;

type FetchRecentPostsThunkArgs = {
  wixCodeApi: IWixAPI;
  request: RequestClient;
  httpClient: HttpClient;
  aggregatorRequest: AggregatorRequest;
  flowAPI: FlowAPI;
};

export default function fetchRecentPosts(
  post: NormalizedPost,
  postCount = 3,
): FetchRecentPostsThunkAction {
  return async (
    dispatch,
    getState,
    { request, httpClient, wixCodeApi, aggregatorRequest, flowAPI },
  ) => {
    const params = { postId: resolveId(post) };
    dispatch(fetchRecentPostsRequest(params));
    const lang = getQueryLocale(getState());
    const promise = request(
      urijs('/_api/posts').query({
        categoryIds: get(post, 'categoryIds', []).join(','),
        excludeIds: resolveId(post),
        size: postCount,
        offset: 0,
        ...(lang && { lang }),
      }),
    );
    const platformizedRecentPostsEnabled = flowAPI.experiments.enabled(
      OOI_EXPERIMENTS.PLATFORMIZED_RECENT_POSTS,
    );
    const editor = getEnvironment(wixCodeApi).isEditorSegment;

    if (platformizedRecentPostsEnabled) {
      try {
        const response = await flowAPI.httpClient.request(
          queryPosts({
            query: {
              filter: {
                ...(lang ? { language: lang } : {}),
                ...(post.categoryIds?.length
                  ? { categoryIds: { $hasSome: post.categoryIds } }
                  : {}),
                id: { $ne: post.id },
              },
              paging: { offset: 0, limit: postCount },
            },
            fieldsets: [Field.METRICS],
          }),
        );

        let posts = response.data.posts;

        if (!posts.length && editor) {
          const fake = await getDemoPosts({
            httpClient,
            getState,
            dispatch,
            aggregatorRequest,
            wixCodeApi,
            query: { pageSize: postCount + 1, excludeIds: [post.id] },
          });

          posts = fake.posts;
        }

        const normalizedPosts = normalizePosts({
          state: getState(),
          posts,
          blogCategoryIds: getCategoryIds(getState()),
          origin: '/v3/posts',
        });

        dispatch(fetchRecentPostsSuccess(normalizedPosts, params));
      } catch (error) {
        dispatch(fetchRecentPostsFailure(undefined, params));
      }
    } else {
      return promise
        .then(async (posts) => {
          if (!posts.length && editor) {
            const fake = await getDemoPosts({
              httpClient,
              getState,
              dispatch,
              aggregatorRequest,
              wixCodeApi,
              query: { pageSize: postCount + 1, excludeIds: [post.id] },
            });
            posts = fake.posts;
          }

          const normalizedPosts = normalizePosts({
            state: getState(),
            posts,
            blogCategoryIds: getCategoryIds(getState()),
          });

          dispatch(fetchRecentPostsSuccess(normalizedPosts, params));
        })
        .catch(() => dispatch(fetchRecentPostsFailure(undefined, params)))
        .then(() => promise);
    }
  };
}
